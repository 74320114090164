import React from 'react';
import styles from 'src/stylesheets/chartViews/topContent/topContentCard.scss';
import PropTypes from 'prop-types';
import DivWithMultiLineEllipsis from 'src/components/DivWithMultiLineEllipsis';
import PostKPIs from 'src/components/views/postTags/PostKPIs';
import AccountInfoListItem from 'src/components/account/layout/AccountInfoListItem';
import PostImage from 'src/components/views/postTags/postTagging/PostImage';
import * as customPropTypes from 'src/customPropTypes';
import TopContentMetricData from 'src/components/chartViews/topContent/TopContentMetricData';

const navigate = (link) => {
    window.open(link, '_blank');
};

const TopContentCard = ({
    pageName,
    content,
    profilePicture,
    thumbnail,
    kpis,
    link,
    data,
    platformType,
    numberFormat
}) => (
    <div className={styles.card} onClick={() => { navigate(link); }}>
        <div className={styles.cardTitle}>
            <AccountInfoListItem
              thumbnail={profilePicture}
              name={pageName}
              platformType={platformType}
            />
        </div>
        <div className={styles.postImage}>
            <PostImage url={thumbnail} width="100%" height="120px" lazyLoad={false} />
        </div>
        <div className={styles.cardBody}>
            <div className={styles.content}>
                {
                    content !== '' && (
                        <DivWithMultiLineEllipsis tooltip={content} lineClamps={3}>
                            <span>{content}</span>
                        </DivWithMultiLineEllipsis>
                    )
                }
                {
                    content === '' && (
                        <div>No content available</div>
                    )
                }
            </div>
            <div className={styles.kpis}>
                <PostKPIs postKpis={kpis} />
            </div>
        </div>
        <div className={styles.footer}>
            <TopContentMetricData data={data} numberFormat={numberFormat} />
        </div>
    </div>
);

TopContentCard.propTypes = {
    pageName: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    profilePicture: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    kpis: customPropTypes.postKpis,
    link: PropTypes.string.isRequired,
    platformType: customPropTypes.platformTypes.isRequired,
    data: customPropTypes.topContentData.isRequired,
    numberFormat: customPropTypes.numberFormat.isRequired
};

TopContentCard.defaultProps = {
    kpis: []
};

export default TopContentCard;
